@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  overflow-x: hidden
}

h1, h2, h3 {
  font-family: Inter, Roboto, sans-serif;
}

@media (max-width: 600px) {
  h1.large {
    font-size: 30px;
  }

  h1 {
    font-size: 28px;
  }
  
  h2 {
    font-size: 22px;
  }
  
  h3 {
    font-size: 18px;
  }
}

@media (min-width: 600px) {
  h1.large {
    font-size: 44px;
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 20px;
  }
}

.caption1 {
  font-size: 14px;
}

.caption2 {
  font-size: 12px;
}


.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

*:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}